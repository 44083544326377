<template>
  <div></div>
</template>

<script>
import Cookies from 'js-cookie'
import { param } from '@/utils'

export default {
  name: 'index',
  created() {
    /* 钉钉登录会重定向地址，只能将这两个数据保存在Cookie，以便下次钉钉跳转回来使用*/
    // if (param('code')) {
    //   Cookies.set('nauth-code', param('code'))
    // }
    // if (param('url')) {
    //   Cookies.set('nauth-redirect', decodeURIComponent(param('url')))
    // }
    
    // 判断code是否存在，存在即用户已经扫码确认登录，重定向到redirect获取登录信息，不存在则回到扫码登录界面
    const code = Cookies.get('nauth-code') || param('code')
    if (code) {
      this.$router.replace('redirect')
    } else {
      this.$router.replace('login')
    }
  }
}
</script>